import React, { useState } from "react";
import logo from "../../assets/gx.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AdminPageLogin = () => {
  const correctEmail = "geekonixtmsl@gmail.com";
  const correctPassword = "2025@admin#geekonix";
  const [Email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    if (Email === correctEmail && password === correctPassword) {
      window.localStorage.setItem("geekuser", "Admin");
      Swal.fire({
        icon: "success",
        title: "Login Successful",
        text: "Welcome to the Admin Panel!",
        timer: 2000,
        showConfirmButton: false,
      });
      setTimeout(() => navigate("/admindashboard"), 2000);
    } else {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: "Invalid email or password. Please try again!",
        confirmButtonColor: "#d33",
      });
    }
  };

  return (
    <main className="flex items-center justify-center min-h-screen bg-primary_black text-gray-200">
      <section className="bg-secondary_black border border-gray-700 rounded-lg p-6 shadow-lg w-96 animate-fadeIn">
        <div className="flex justify-center mb-4">
          <img src={logo} alt="Geekonix Logo" className="h-24 w-24" />
        </div>
        <h2 className="text-center text-xl font-semibold mb-4">Admin Login</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-sm">Email</label>
            <input
              type="email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@gmail.com"
              className="w-full px-3 py-2 rounded-md bg-gray-700 focus:outline-none focus:ring-2 focus:ring-red"
            />
          </div>
          <div>
            <label className="block text-sm">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="********"
              className="w-full px-3 py-2 rounded-md bg-gray-700 focus:outline-none focus:ring-2 focus:ring-red"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-red hover:bg-red_second text-white font-bold py-2 rounded-md transition"
          >
            Login
          </button>
        </form>
      </section>
    </main>
  );
};

export default AdminPageLogin;
