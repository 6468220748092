import React from "react";
import Tilt from "react-parallax-tilt";
import club1 from "../assets/club_cards/cybercrusade.svg";
import club2 from "../assets/club_cards/ciic.svg";
import club3 from "../assets/club_cards/createit.svg";
import club4 from "../assets/club_cards/curbrain.svg";
import club5 from "../assets/club_cards/elevation.svg";
import club7 from "../assets/club_cards/foodforfun.svg";
import club8 from "../assets/club_cards/funevents.svg";
import club9 from "../assets/club_cards/geekdesign.svg";
// import club10 from '../../assets/club/GxD-Elevation-Logo-Black.png'
import club11 from "../assets/club_cards/infocus.svg";
import club12 from "../assets/club/JUST LIKE THAT BLACK.png";
import club13 from "../assets/club_cards/newron.svg";
import club14 from "../assets/club_cards/RRC-White.png";
import club15 from "../assets/club_cards/Editorial - White.png";
import HomeNav from "components/nav/HomeNav";
const Registration = () => {
  const clubs = [
    {
      image: club1,
      name: "Cyber Crusade",
      url: "https://forms.gle/tXWFA43dnQWM2FGFA",
      rulebook: "/Rulebooks/ccrb.pdf",
    },
    {
      image: club2,
      name: "CIIC",
      url: "https://forms.gle/YSfy1v2z83dqZLP96",
      rulebook: "/Rulebooks/CIIC.docx.pdf",
    },
    {
      image: club3,
      name: "Create-IT",
      url: "https://forms.gle/iRN7ttBmhPe1Jb2r7",
      rulebook: "/Rulebooks/CREATEITANDINNOVATI.pdf",
    },
    {
      image: club4,
      name: "Curbrain X GDG",
      url: "https://forms.gle/LwXbnA3uaMFfdCFf7",
      rulebook: "/Rulebooks/CUrBrainRuleBook.pdf",
    },
    {
      image: club5,
      name: "Elevation",
      url: "https://forms.gle/yab3UBfxEJCRs9XR9",
      rulebook: "/Rulebooks/ELEVATIONRULEBOOK.pdf",
    },
    {
      image: club7,
      name: "Food-For-Fun",
      url: "https://forms.gle/1v2bkHFtm4y6b9za7",
      rulebook: "/Rulebooks/fffedge.pdf",
    },
    {
      image: club14,
      name: "RRC Autonomous",
      url: "https://forms.gle/6UKLNie1rysyzq7G9",
      rulebook: "/Rulebooks/rrcautonomous.pdf",
    },
    {
      image: club9,
      name: "Geek-Designs",
      url: "https://forms.gle/fAKJdwM4zzDPaoEQ7",
      rulebook: "/Rulebooks/GEEKDESIGNS.pdf",
    },
    {
      image: club11,
      name: "Infocus",
      url: "https://forms.gle/YJTmcj6ZBThCRxkVA",
      rulebook: "/Rulebooks/infocusrb.pdf",
    },
    {
      image: club12,
      name: "Just-Like-that",
      url: "https://forms.gle/d5mEnUqsmERrejHp7",
      rulebook: "/Rulebooks/jltrb.pdf",
    },
    {
      image: club13,
      name: "Newron",
      url: "https://forms.gle/MtneSbopknrvDDKs7",
      rulebook: "/Rulebooks/NewronRulebook.pdf",
    },
    {
      image: club14,
      name: "RRC Manual",
      url: "https://forms.gle/m3D7NBPxBvpW6hr9A",
      rulebook: "/Rulebooks/RRCManual.pdf",
    },
    {
      image: club15,
      name: "Editorial",
      url: "https://forms.gle/hTo75YcyJ4JVEQmE6",
      rulebook: "/Rulebooks/Editorial_Rule Book.pdf",
    },
  ];
  return (
    <main className="">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
      >
        <source src="/videos/Home-bg-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <HomeNav />
      <section className="m-10">
        <h1 className="text-center text-6xl md:text-7xl text-red font-black-hawk my-5  relative font-bold">
          <span className="text-white ">R</span>egistration{" "}
        </h1>
        <div className="flex justify-center items-center mx-auto">
          <div className="  flex justify-center items-center flex-wrap gap-10">
            {clubs.map((club, i) => (
              <div key={i}>
                <Tilt tiltMaxAngleX={2.5} tiltMaxAngleY={1} perspective={500}>
                  <div class="relative  flex flex-col  my-6 bg-gradient-to-tr from-gray-400 to-gray-700 shadow-sm border border-slate-200 rounded-lg w-96">
                    <div class="relative p-2.5 h-96 overflow-hidden rounded-xl bg-clip-border">
                      <img
                        src={club.image}
                        alt="card-image"
                        class="h-full w-full object-contain rounded-md"
                      />
                    </div>
                    <div class="p-4">
                      <div class="mb-2 flex items-center justify-between">
                        <p class="text-slate-300 text-xl font-semibold">
                          {club.name}
                        </p>
                        <div className="flex flex-col items-center justify-center">
                          {club.rulebook ? (
                            <a
                              class="rounded-md w-full mt-2 bg-cyan-600 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-cyan-700 focus:shadow-none active:bg-cyan-700 hover:bg-cyan-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                              href={club.rulebook}
                              target="_blank"
                            >
                              RuleBook
                            </a>
                          ) : null}

                          <a
                            class="rounded-md w-full mt-2 bg-cyan-600 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-cyan-700 focus:shadow-none active:bg-cyan-700 hover:bg-cyan-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            href={club.url}
                            target="_blank"
                          >
                            Register Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tilt>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Registration;
