import React, { useRef, useState, useEffect } from "react";
import Nav from "components/nav/Navbar";
import geekDesgin from "../../assets/clubcards2025/GEEKDESIGN.png";
import RRCAuto from "../../assets/clubcards2025/RRCautonomous.png";
import Editorial from "../../assets/clubcards2025/EDITORIAL.png";
import Elevation from "../../assets/clubcards2025/Elevation.png";
import CurBrain from "../../assets/clubcards2025/CurBrainGDG.jpg";
import justLikethat from "../../assets/clubcards2025/JUSTLIKETHAT.png";
import creatItInnova from "../../assets/clubcards2025/CREATEIT.png";
import infocusev from "../../assets/clubcards2025/event10.jpg";
import foodforfunev from "../../assets/clubcards2025/FOODFORFUN.png";
import RRCMan from "../../assets/clubcards2025/RRCManual.png";
import CYberev from "../../assets/clubcards2025/cc.jpg";
import CiicEv from "../../assets/clubcards2025/ciicevent.jpg";
import Newron from "../../assets/clubcards2025/Newron.PNG";
import HomeNav from "components/nav/HomeNav";

const clubCards = [
  {
    id: "1",
    clubimage: "/images/club_cards/ciic.svg",
    title: "CIIC",
    backgroundImage: CiicEv,
  },
  {
    id: "2",
    clubimage: "/images/club_cards/curbrain.svg",
    title: "GDG X Curbrain",
    backgroundImage: CurBrain,
  },
  {
    id: "3",
    clubimage: "/images/club_cards/createit.svg",
    title: "Create IT",
    backgroundImage: creatItInnova,
  },
  {
    id: "4",
    clubimage: "/images/club_cards/elevation.svg",
    title: "Elevation",
    backgroundImage: Elevation,
  },
  {
    id: "5",
    clubimage: "/images/club_cards/cybercrusade.svg",
    title: "Cyber Crusade",
    backgroundImage: CYberev,
  },
  {
    id: "6",
    clubimage: "/images/club_cards/foodforfun.svg",
    title: "Food For Fun",
    backgroundImage: foodforfunev,
  },

  {
    id: "13",
    clubimage: "",
    title: "Just like that ",
    backgroundImage: justLikethat,
  },
  {
    id: "14",
    clubimage: "",
    title: "Editorial",
    backgroundImage: Editorial,
  },
  {
    id: "8",
    clubimage: "/images/club_cards/geekdesign.svg",
    title: "Geek Design",
    backgroundImage: geekDesgin,
  },
  {
    id: "9",
    clubimage: "/images/club_cards/infocus.svg",
    title: "In Focus",
    backgroundImage: infocusev,
  },
  {
    id: "10",
    clubimage: "/images/club_cards/newron.svg",
    title: "Newron",
    backgroundImage: Newron,
  },
  {
    id: "11",
    clubimage: "/images/club_cards/rrc.svg",
    title: "RRC",
    backgroundImage: RRCAuto,
  },
  {
    id: "12",
    clubimage: "/images/club_cards/rrc.svg",
    title: "RRC",
    backgroundImage: RRCMan,
  },
];

const eventDetails = [
  {
    _id: "626edc2f5c66fb363925ecb0",
    eventCode: "CUR001",
    title: "FLAWLESS",
    club: "curbrain",
    image: "/images/event-icons/curbrain/flawless.svg",
  },
  {
    _id: "626edf425c66fb363925ecbd",
    eventCode: "RRC001",
    title: "ROBO RACE",
    club: "rrc",
    image: "/images/event-icons/rrc/roborace.svg",
  },
  {
    _id: "626ee08c5c66fb363925eccd",
    eventCode: "RRC002",
    title: "ROBO SOCCER",
    club: "rrc",
    image: "/images/event-icons/rrc/robosoccer.svg",
  },
  {
    _id: "626ee2d65c66fb363925ece3",
    eventCode: "RRC004",
    title: "MINI WAR",
    club: "rrc",
    image: "/images/event-icons/rrc/MiniRobowar.svg",
  },
  {
    _id: "626ee4cd5c66fb363925ecf7",
    eventCode: "RRC005",
    title: "WAR",
    club: "rrc",
    image: "/images/event-icons/rrc/robowar.svg",
  },
  {
    _id: "626ee6185c66fb363925ed0b",
    eventCode: "CUR002",
    title: "CRYPTOQUEST",
    club: "curbrain",
    image: "/images/event-icons/curbrain/cryptoquest.svg",
  },
  {
    _id: "626ee7435c66fb363925ed13",
    eventCode: "ELE001",
    title: "NIRMAAN",
    club: "elevation",
    image: "/images/event-icons/elevation/NIRMAAN.svg",
  },
  {
    _id: "626ee85c5c66fb363925ed1f",
    eventCode: "CUR003",
    title: "BUGHUNT",
    club: "curbrain",
    image: "/images/event-icons/curbrain/bughunt.svg",
  },
  {
    _id: "626eedd35c66fb363925ed4b",
    eventCode: "CUR004",
    title: "GEEKATHON",
    club: "curbrain",
    image: "/images/event-icons/curbrain/geekathon.svg",
  },
  {
    _id: "626eef035c66fb363925ed54",
    eventCode: "ELE002",
    title: "PAPER-O-VATION",
    club: "elevation",
    image: "/images/event-icons/elevation/PAPEROVATION.svg",
  },
  {
    _id: "626ef4f65c66fb363925ed81",
    eventCode: "CIIC001",
    title: "BOARDROOM",
    club: "ciic",
    image: "",
  },
  {
    _id: "626ef5e75c66fb363925ed86",
    eventCode: "CIIC002",
    title: "BUSINESS MODEL PLAN",
    club: "ciic",
  },
  {
    _id: "626ef7065c66fb363925ed8d",
    eventCode: "CR001",
    title: "MEKANIX",
    club: "createit",
  },
  {
    _id: "626ef7515c66fb363925ed9d",
    eventCode: "CIIC003",
    title: "B-QUIZ",
    club: "ciic",
  },
  {
    _id: "626ef82c5c66fb363925edac",
    eventCode: "CIIC004",
    title: "BRAND IT",
    club: "ciic",
  },
  {
    _id: "626efa3a5c66fb363925edc0",
    eventCode: "FFF001",
    title: "FOOD PHOTOGRAPHY(Online)",
    club: "foodforfun",
  },
  {
    _id: "626efb465c66fb363925edc8",
    eventCode: "INF001",
    title: "SHOOT-M-UP",
    club: "infocus",
    image: "/images/event-icons/infocus/shootmup.svg",
  },
  {
    _id: "626efc1b5c66fb363925edd5",
    eventCode: "INF002",
    title: "PHOTO STORY",
    club: "infocus",
    image: "/images/event-icons/infocus/Photo-story.svg",
  },
  {
    _id: "626efca45c66fb363925eddd",
    eventCode: "FFF003",
    title: "PITCH THE LABEL(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626efdbf5c66fb363925ede6",
    eventCode: "FFF004",
    title: "FOOD RELAY(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626efeb05c66fb363925edf2",
    eventCode: "INF003",
    title: "CRUMBS",
    club: "infocus",
    image: "/images/event-icons/infocus/Crumbs.svg",
  },
  {
    _id: "626efec15c66fb363925edf6",
    eventCode: "FFF005",
    title: "PROCESS PUZZLE(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626effb75c66fb363925ee04",
    eventCode: "FFF006",
    title: "FOOD DEBATE(Offline)",
    club: "foodforfun",
  },
  {
    _id: "626face05c66fb363925ee8c",
    eventCode: "NEW001",
    title: "METAVERSE OF MADNESS",
    club: "newron",
    image: "/images/event-icons/newron/metaverse.svg",
  },
  {
    _id: "626faedf5c66fb363925ee92",
    eventCode: "NEW002",
    title: "MIND-CRAFT and QUIZZARDRY",
    club: "newron",
    image: "/images/event-icons/newron/mindcraft.svg",
  },
  {
    _id: "626fb3b15c66fb363925eea0",
    eventCode: "NEW003",
    title: "ELECTRONICALLY YOURS",
    club: "newron",
    image: "/images/event-icons/newron/electronically-yours.svg",
  },
  {
    _id: "626fb9385c66fb363925eeca",
    eventCode: "RRC006",
    title: "TRACK HUNTER",
    club: "rrc",
    image: "/images/event-icons/rrc/trackhunter.svg",
  },
  {
    _id: "626fbe5b5c66fb363925eed7",
    eventCode: "RRC007",
    title: "JIGSAW",
    club: "rrc",
    image: "/images/event-icons/rrc/jigsaw.svg",
  },
  {
    _id: "626fc09a5c66fb363925eee3",
    eventCode: "RRC008",
    title: "WALL STREET",
    club: "rrc",
    image: "/images/event-icons/rrc/wallstreet.svg",
  },
  {
    _id: "627031e35c66fb363925f004",
    eventCode: "GD001",
    title: "INTER-ACTION",
    club: "geekdesign",
    image: "/images/event-icons/geek-design/interaction.svg",
  },
  {
    _id: "627036985c66fb363925f03e",
    eventCode: "GD002",
    title: "MOTION CANVAS",
    club: "geekdesign",
    image: "/images/event-icons/geek-design/motion.svg",
  },
  {
    _id: "6270372e5c66fb363925f04c",
    eventCode: "NEW004",
    title: "YOUTH PARLIAMENT",
    club: "newron",
    image: "/images/event-icons/newron/youth-parliament.svg",
  },
  {
    _id: "6270395c5c66fb363925f082",
    eventCode: "GD003",
    title: "PIXEL PLOT",
    club: "geekdesign",
    image: "/images/event-icons/geek-design/pixel.svg",
  },
  {
    _id: "627144085c66fb363925f25f",
    eventCode: "CC001",
    title: "VALORANT TECHNO CHAMPIONS TOUR",
    club: "cybercrusade",
    image: "/images/event-icons/cc/valorant.svg",
  },
  {
    _id: "627146bd5c66fb363925f264",
    eventCode: "CC002",
    title: "BGMI CHAMPIONSHIP",
    club: "cybercrusade",
    image: "/images/event-icons/cc/bgmi.svg",
  },
  {
    _id: "627149655c66fb363925f276",
    eventCode: "CC003",
    title: "FIFA 22",
    club: "cybercrusade",
    image: "/images/event-icons/cc/fifa22.svg",
  },
  {
    _id: "627270385c66fb363925f723",
    eventCode: "CC004",
    title: "CS GO Pro-League",
    club: "cybercrusade",
    image: "/images/event-icons/cc/csgo.svg",
  },
];

function NewEvent() {
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedClub, setSelectedClub] = useState(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const scrollAmount = 450; // Adjust based on card width

  const updateScrollButtons = () => {
    if (!scrollRef.current) return;
    setCanScrollLeft(scrollRef.current.scrollLeft > 0);
    setCanScrollRight(
      scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
        scrollRef.current.scrollWidth
    );
  };

  const scrollLeftFunc = () => {
    if (!scrollRef.current || !canScrollLeft) return;
    scrollRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    setTimeout(updateScrollButtons, 300);
  };

  // Scroll right by one card width
  const scrollRightFunc = () => {
    if (!scrollRef.current || !canScrollRight) return;
    scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    setTimeout(updateScrollButtons, 300);
  };

  // Check scroll position on mount and whenever scrolling happens
  useEffect(() => {
    if (!scrollRef.current) return;
    updateScrollButtons();
    scrollRef.current.addEventListener("scroll", updateScrollButtons);
    return () =>
      scrollRef.current?.removeEventListener("scroll", updateScrollButtons);
  }, []);

  const handleMouseDown = (e) => {
    if (!scrollRef.current) return;
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust speed for smooth scrolling
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleWheelScroll = (e) => {
      if (!scrollRef.current) return;
      e.preventDefault();
      let delta = e.deltaY * 2; // Adjust speed
      let start = scrollRef.current.scrollLeft;
      let startTime = performance.now();

      const smoothScroll = (timestamp) => {
        let elapsed = timestamp - startTime;
        let progress = Math.min(elapsed / 300, 1); // Ease-out effect
        let easeOut = progress * (2 - progress);
        scrollRef.current.scrollLeft = start + delta * easeOut;

        if (progress < 1) {
          requestAnimationFrame(smoothScroll);
        }
      };

      requestAnimationFrame(smoothScroll);
    };

    // Touch events for mobile and touchpad support
    const handleTouchStart = (e) => {
      if (!scrollRef.current) return;
      setIsDragging(true);
      setStartX(e.touches[0].pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleTouchMove = (e) => {
      if (!isDragging || !scrollRef.current) return;
      e.preventDefault();
      const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
      const walk = (x - startX) * 2; // Adjust speed
      scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
    };

    // Trackpad/touchpad horizontal scroll support
    const handleTrackpadScroll = (e) => {
      if (!scrollRef.current) return;
      // Check if this is likely a horizontal trackpad gesture
      if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
        e.preventDefault();
        scrollRef.current.scrollLeft += e.deltaX;
      }
    };

    const element = scrollRef.current;
    if (element) {
      // Mouse wheel events
      element.addEventListener("wheel", handleWheelScroll, { passive: false });

      // Add touchpad/trackpad horizontal scroll support
      element.addEventListener("wheel", handleTrackpadScroll, {
        passive: false,
      });

      // Touch events for mobile and touchpad
      element.addEventListener("touchstart", handleTouchStart, {
        passive: true,
      });
      element.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      element.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (element) {
        // Clean up all event listeners
        element.removeEventListener("wheel", handleWheelScroll);
        element.removeEventListener("wheel", handleTrackpadScroll);
        element.removeEventListener("touchstart", handleTouchStart);
        element.removeEventListener("touchmove", handleTouchMove);
        element.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [isDragging, scrollLeft, startX]);

  return (
    <div className="event-gradient flex flex-col w-full h-screen relative bg-black text-white">
      {/* Navbar at the top */}
      <div className="sticky top-0 z-10 w-full">
        <HomeNav />
      </div>

      {/* Main content */}
      <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="clubs-heading w-full md:w-[220px] md:h-full h-[60px] flex items-center justify-between px-4 bg-gray-800 md:bg-transparent fixed bottom-0 left-0 md:relative md:top-0 z-20">
          <button
            className={`p-2 cursor-pointer bg-red-600 opacity-75 rounded-full text-white text-xl md:text-2xl shadow-md ${
              !canScrollLeft ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={scrollLeftFunc}
            disabled={!canScrollLeft}
          >
            ❮
          </button>
          <span className="text-5xl font-black-hawk md:text-7xl font-semibold uppercase text-center md:[writing-mode:vertical-rl] md:scale-y-[-1] md:scale-x-[-1]">
            <span className="text-red font-black-hawk font-bold">E</span>VENTS
          </span>
          <button
            className={`p-2 cursor-pointer bg-red-600 opacity-75 rounded-full text-white text-xl md:text-2xl shadow-md ${
              !canScrollRight ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={scrollRightFunc}
            disabled={!canScrollRight}
          >
            ❯
          </button>
        </div>

        {/* Scrollable Club Cards Section */}
        <div
          ref={scrollRef}
          className={`event-gradient flex overflow-x-auto w-full h-full pb-[60px] md:pb-0 whitespace-nowrap cursor-grab ${
            isDragging ? "cursor-grabbing" : ""
          }`}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
        >
          {clubCards.map((club) => (
            <div
              key={club.id}
              className="event-eventPoster min-w-[90vw] md:min-w-[35vw] border border-red bg-gray-950 h-[85vh] md:h-full shadow-xl mx-[2px] flex flex-col items-start text-white text-xl md:text-3xl p-4 md:p-6 hover:bg-gray-900 transition-all cursor-pointer"
              // onClick={() => setSelectedClub(club)}
              style={{
                backgroundImage: `url(${club.backgroundImage})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
              }}
            >
              <div className="flex gap-4 items-center z-10">
                {/* <img
                src={club.clubimage}
                alt={club.title}
                className="w-16 h-16 md:w-24 md:h-24 object-contain"
              /> */}
                <span className="w-full md:w-5 text-2xl md:text-4xl font-semibold text-red break-words">
                  {/* {club.title} */}
                </span>
              </div>
              {/* Add overlay for better text visibility */}
              <div className="absolute inset-0 bg-black bg-opacity-40 pointer-events-none"></div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for selected club */}
      <div>
        {selectedClub && (
          <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-900 text-white p-4 md:p-6 rounded-lg shadow-lg w-[95%] md:w-[50%] max-h-[80vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-4 md:mb-6">
                <h2 className="text-2xl md:text-4xl font-bold text-red">
                  {selectedClub.title} Events
                </h2>
                <button
                  className="text-red text-2xl md:text-3xl font-bold hover:text-red-400 transition-colors"
                  onClick={() => setSelectedClub(null)}
                >
                  ✖
                </button>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
                {/* Display event details if club matches */}
                {eventDetails
                  .filter(
                    (event) =>
                      event.club.toLowerCase() ===
                      selectedClub.title.toLowerCase()
                  )
                  .map((event) => (
                    <div
                      key={event._id}
                      className="flex flex-col items-center bg-gray-800 p-3 md:p-4 rounded-lg hover:bg-gray-700 transition-colors"
                    >
                      {event.image ? (
                        <img
                          src={event.image}
                          alt={event.title}
                          className="w-32 h-32 md:w-48 md:h-48 object-contain cursor-pointer transition-transform transform hover:scale-105"
                        />
                      ) : (
                        <div className="w-32 h-32 md:w-48 md:h-48 flex items-center justify-center bg-gray-700 rounded-lg">
                          <span className="text-lg md:text-2xl text-gray-400">
                            No Image
                          </span>
                        </div>
                      )}
                      <p className="text-xl md:text-2xl mt-3 md:mt-4 font-semibold text-center">
                        {event.title}
                      </p>
                      <span className="text-base md:text-lg text-red">
                        Code: {event.eventCode}
                      </span>
                    </div>
                  ))}

                {/* If no events found */}
                {eventDetails.filter(
                  (event) =>
                    event.club.toLowerCase() ===
                    selectedClub.title.toLowerCase()
                ).length === 0 && (
                  <div className="col-span-2 text-lg md:text-xl text-gray-400 text-center py-8 md:py-12">
                    No events available for {selectedClub.title}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewEvent;
